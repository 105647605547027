<template>
    <v-container>
        <amp-banner
            v-if="$vuetify.breakpoint.mdAndUp && (isEditor || isAuthor)"
            class="my-4"
        />
        <welcome-banner v-else v-show="!isLoading" class="my-4" />

        <my-assignments
            :assignments="assignments"
            :in-progress-count="inProgressCount"
            :in-editorial-count="inEditorialCount"
            :loading="isLoading"
            class="px-0"
            @update="update"
        />

        <v-container class="px-0 pb-0">
            <v-row class="justify-space-between">
                <v-col cols="12" md="auto">
                    <div class="text-h6 text-md-h5 font-weight-bold">
                        Request Pool
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    md="auto"
                    class="d-flex flex-wrap dense-fields"
                >
                    <v-switch
                        v-model="showLimitedDistribution"
                        label="Selective Distribution"
                        :disabled="isLoading"
                        inset
                        dense
                        class="mt-0 ml-12"
                    />
                    <v-radio-group
                        v-model="showAssigned"
                        row
                        dense
                        :disabled="isLoading"
                        class="ml-12 mt-0"
                    >
                        <v-radio
                            key="show-assigned-radio_group-1"
                            label="All"
                            :value="0"
                        />
                        <v-radio
                            key="show-assigned-radio_group-2"
                            label="Assigned"
                            :value="1"
                        />
                        <v-radio
                            key="show-assigned-radio_group-3"
                            label="Unassigned"
                            :value="2"
                        />
                    </v-radio-group>
                    <author-selector
                        v-if="isEditor && displayingAssigned"
                        :authors="authors"
                        :loading="isLoading"
                        :query="{ showAssigned: 1 }"
                        class="ml-12 authors--select"
                        can-be-empty
                    />
                </v-col>
            </v-row>
        </v-container>

        <assignments-list
            :assignments="assignment_pool"
            :loading="isLoading"
            :updating="isUpdating"
            class="px-0 pt-0"
            @update="update"
        />

        <paging
            :total="total"
            :default-per-page="perPage"
            :loading="isUpdating"
            size="10"
            class="main-background px-0"
        />

        <training-section class="mt-10" />

        <training-link v-if="canSeeTrainingLink && !isLoading" class="mt-4" />
    </v-container>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';

import { AmpBanner } from '@/components/AmpBanner';
import { AuthorSelector } from '@/components/AuthorSelector';
import { WelcomeBanner } from '@/components/WelcomeBanner';
import { AnnouncementsClientSelector } from '@/components/AnnouncementsClientSelector';
import { Paging } from '@/components/Paging';
import { TrainingLink, TrainingSection } from '@/components/Training';

import MyAssignments from './MyAssignments.vue';

import { AssignmentsList } from '@/components/Authoring/AssignmentsList';

import { ListPaged, Training, UserId } from '@/mixins';

import type { Assignment } from '@/types/Assignment';
import type { AuthorUser, ClientUser } from '@/types/User';

@Component({
    components: {
        AmpBanner,
        WelcomeBanner,
        AnnouncementsClientSelector,
        AuthorSelector,
        MyAssignments,
        AssignmentsList,
        Paging,
        TrainingSection,
        TrainingLink
    },
    computed: {
        ...mapState('clients', ['clients']),
        ...mapState('authors', ['authors']),
        ...mapGetters('user', ['isEditor'])
    }
})
export default class AuthorRequests extends mixins(
    ListPaged,
    Training,
    UserId
) {
    clients!: ClientUser[];
    authors!: AuthorUser[];

    isEditor!: boolean;

    assignments: Assignment[] = [];

    assignment_pool: Assignment[] = [];

    inEditorialCount = 0;
    inProgressCount = 0;

    endpoint = '/author_requests_pool';

    get queryParams() {
        return {
            user_id: this.userId,
            author_id: 0,
            limit: 10,
            page: 1,
            sort: 'priority',
            asc: 1,
            showLimitedDistribution: 0,
            showAssigned: 2
        };
    }

    get showLimitedDistribution() {
        return this.$route.query.showLimitedDistribution === '1';
    }

    set showLimitedDistribution(showLimitedDistribution: boolean) {
        this.$router.push({
            path: this.$route.path,
            query: {
                ...this.$route.query,
                page: void 0,
                showLimitedDistribution: showLimitedDistribution ? '1' : void 0
            }
        });
    }

    get showAssigned() {
        return Number(this.$route.query.showAssigned || '2');
    }

    set showAssigned(showAssigned: number) {
        this.$router.push({
            path: this.$route.path,
            query: {
                ...this.$route.query,
                page: void 0,
                author_id: void 0,
                showAssigned: showAssigned === 2 ? void 0 : String(showAssigned)
            }
        });
    }

    get displayingAll() {
        return this.showAssigned === 0;
    }

    get displayingAssigned() {
        return this.showAssigned === 1;
    }

    get displayingUnassigned() {
        return this.showAssigned === 2;
    }

    onData(data: {
        assignments: Assignment[];
        assignment_pool: Assignment[];
        clients: Record<string, string>;
        authors: Record<string, string>;
        length: number;
        inProgressCount: number;
        inEditorialCount: number;
    }) {
        if (data) {
            this.assignments = data.assignments || [];
            this.assignment_pool = data.assignment_pool || [];

            this.total = data.length;
            this.inEditorialCount = data.inEditorialCount;
            this.inProgressCount = data.inProgressCount;

            this.$store.dispatch('clients/set', data.clients);
            this.$store.dispatch('authors/set', data.authors);
        }
    }
}
</script>

<style lang="scss" scoped>
.authors--select {
    max-width: min-content !important;
    padding: 0;
    margin: 0;
    min-width: 200px;
    position: relative;
    top: -5px;
}
</style>
