<template>
    <v-autocomplete
        v-if="isReseller"
        ref="autocomplete"
        filled
        hint="Type or select a client"
        persistent-hint
        :value="active"
        :items="clientsWithMe"
        :loading="loading"
        hide-no-data
        hide-selected
        item-text="email"
        item-value="id"
        :menu-props="{ maxWidth }"
        v-bind="$attrs"
        class="client-selector"
        @input="select"
    />
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';

import type { VAutocomplete } from 'vuetify/lib';
import type { ClientUser } from '@/types/User';

type MenuItems = (ClientUser | { divider: boolean })[];

const ClientSelectorProps = Vue.extend({
    name: 'ClientSelector',
    props: {
        clients: {
            type: Array as PropType<ClientUser[]>,
            default() {
                return [];
            }
        },
        canBeEmpty: {
            type: Boolean,
            default() {
                return false;
            }
        },
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    computed: {
        ...mapGetters('user', ['isReseller'])
    }
})
export default class ClientSelector extends ClientSelectorProps {
    isReseller!: boolean;

    $refs!: {
        autocomplete: InstanceType<typeof VAutocomplete> & {
            blur: () => void;
        };
    };

    maxWidth = '50vw'; // don't allow long titles expand dropdown too much

    get active() {
        return this.activeClient || this.defaultUser;
    }

    get activeUser() {
        return Number(this.$store.state.user.id);
    }

    get activeClient() {
        return Number(this.$route.query.user_id) || 0;
    }

    get defaultUser() {
        return this.canBeEmpty ? 0 : this.activeUser;
    }

    get clientsWithMe(): MenuItems {
        const alteredUsers: MenuItems = [];

        if (this.canBeEmpty) {
            alteredUsers.push({
                id: 0,
                email: this.emptyEntryLabel
            });
        }

        const me = this.clients.find(user => user.id === this.activeUser);

        if (me) {
            alteredUsers.push({ ...me, email: `Myself (${me.email})` });

            if (
                (this.activeClient && this.activeClient !== me.id) ||
                this.canBeEmpty
            ) {
                alteredUsers.push({ divider: true });
            }

            return [
                ...alteredUsers,
                ...this.clients.filter(user => user.id !== me.id)
            ];
        } else {
            return [...alteredUsers, ...this.clients];
        }
    }

    get emptyEntryLabel() {
        return this.activeClient ? 'Show All Clients' : 'Showing All Clients';
    }

    mounted() {
        // inherit the width of the control
        if (this.$refs.autocomplete) {
            this.maxWidth = `${this.$refs.autocomplete.$el.clientWidth}`;
        }
    }

    select(value: string) {
        const isDefaultValue = Number(value) === this.defaultUser;

        this.$router.push({
            path: this.$route.path,
            query: {
                user_id: isDefaultValue ? void 0 : value
            }
        });
        // let empty entry update its title
        if (this.canBeEmpty && isDefaultValue) {
            this.$refs.autocomplete?.blur();
        }
    }
}
</script>

<style lang="scss" scoped>
.client-selector::v-deep {
    .v-input__control {
        .v-input__slot {
            font-weight: 500;
        }
    }
}
</style>
