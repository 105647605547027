<template>
    <v-sheet elevation="1" width="100%" height="80" class="pa-4">
        <v-skeleton-loader
            v-if="loading"
            type="list-item-avatar"
            class="ml-n3"
        />
        <h1 v-else>
            {{ value }}
            <small v-if="label" class="pl-2 text-subtitle-1">
                {{ label }}
            </small>
        </h1>
    </v-sheet>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

const StatsSheetProps = Vue.extend({
    name: 'StatsSheet',
    props: {
        loading: {
            type: Boolean,
            default() {
                return true;
            }
        },
        value: {
            type: [String, Number],
            default() {
                return 0;
            }
        },
        label: {
            type: String,
            default() {
                return '';
            }
        }
    }
});

@Component
export default class StatsSheet extends StatsSheetProps {}
</script>
