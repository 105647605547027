<template>
    <a-banner v-if="!isKamManagedUser">
        <!-- for reference only <span
            v-if="
                isBetween(['2024-07-03 11:00'], ['2024-07-07 11:00']) &&
                !isNewSimplerTrafficBuyer
            "
        >
            Discover Our New Traffic Playbook For 2024
            <a href="https://letsgolook.at/TrafficPlaybook2024" target="_blank">
                <v-icon color="primary" x-small>arrow-right</v-icon>
                RSVP HERE
            </a>
        </span> -->
        <!-- Banners For All Users -->
        <div
            v-if="isBetween(['2024-10-15 11:00'], ['2024-10-16 11:00'])"
            class="my-4"
        >
            [FREE BOOK!] Discover The New & Unusual “Affiliate-Style” Method We
            Use To Quickly Set Up Highly Profitable Blue Ocean Revenue Streams
            And Stack $700/mo Commissions
            <a href="https://letsgolook.at/DropperBook" target="_blank">
                <v-icon color="primary" x-small>arrow-right</v-icon>
                Get Your Free Book Here!
            </a>
        </div>

        <div
            v-if="isBetween(['2024-10-16 11:00'], ['2024-10-17 11:00'])"
            class="my-4"
        >
            [FREE BLUEPRINT!] Revealing The 3 Simple Steps We Take To Stack
            $700/mo Commissions In Untapped Niches (Without Inventory Or Paid
            Ads!)
            <a href="https://letsgolook.at/DropperBlueprint" target="_blank">
                <v-icon color="primary" x-small>arrow-right</v-icon>
                Get Your Free Blueprint Here!
            </a>
        </div>

        <div
            v-if="isBetween(['2024-10-17 11:00'], ['2024-10-19 23:59'])"
            class="my-4"
        >
            [DAY #1] Join A Live 3-Day Competition To Make Your First $700/mo
            Commissions With A Special Kind Of Virtual Product Rich People Want
            <a href="https://letsgolook.at/DropServiceGames" target="_blank">
                <v-icon color="primary" x-small>arrow-right</v-icon>
                Take Part In The Games Here!
            </a>
        </div>

        <div
            v-if="isBetween(['2024-10-20 00:00'], ['2024-10-20 23:59'])"
            class="my-4"
        >
            [Day #2] Discover Our Zero-Dollar Method To Quickly Drive Wealthy
            Visitors To A High-Converting $1,000/mo Offer FOR FREE! (No Paid
            Traffic)
            <a href="https://letsgolook.at/DropServiceGames" target="_blank">
                <v-icon color="primary" x-small>arrow-right</v-icon>
                RSVP To The Games Now!
            </a>
        </div>

        <div
            v-if="isBetween(['2024-10-21 00:00'], ['2024-10-21 23:59'])"
            class="my-4"
        >
            [Day 3] How To Scale With A New Type Of Automated Store Selling
            $1,000/mo Virtual Products To Rich People (At 70% Profit!)
            <a href="https://letsgolook.at/DropServiceGames" target="_blank">
                <v-icon color="primary" x-small>arrow-right</v-icon>
                RSVP To The Games Now!
            </a>
        </div>

        <div v-if="isAsigoBuyerOnly" class="mb-4">
            We are excited to announce that Asigo has been upgraded to DropServe
            with new sales material, new software and new strategies to help you
            maximize your sales and profits.
            <br />
            Review our new training to discover the new platform and take part
            in our latest challenge and to grow your hands-off agency.
            <br />
            Join the sessions
            <a href="/training/dropserve/index" target="_blank">
                <v-icon color="primary" x-small>arrow-right</v-icon>
                here
            </a>
        </div>

        <div v-if="isDropServeBuyer" class="my-4">
            Welcome to the DropServe, your DropService whitelabel platform where
            we will guide you on starting and scaling your own DropService
            business. Start here:
            <a href="https://www.dropserve.ai/thankyou" target="_blank">
                https://www.dropserve.ai/thankyou
            </a>
        </div>
        <!-- Banners for Non-DropServe Buyers -->
        <div
            v-if="
                !isDropServeBuyer &&
                isBetween(['2024-10-22 00:00'], ['2024-10-26 11:00'])
            "
            class="my-4"
        >
            [RSVP NOW!] Discover The New Type of Automated Store I Built To Sell
            $1,000/mo Virtual Products To Rich People (At 70% Profit Margins!)
            <a href="https://letsgolook.at/DropServiceProfits" target="_blank">
                <v-icon color="primary" x-small>arrow-right</v-icon>
                RSVP For The Next Workshop Here!
            </a>
        </div>
        <div
            v-if="
                !isDropServeBuyer &&
                isBetween(['2024-10-26 11:00'], ['2024-10-28 11:00'])
            "
            class="my-4"
        >
            [ACT FAST!] The Final “High-Profit DropStore Accelerators” Have
            Almost All Been Claimed And ALL ACCESS CLOSES This Weekend!
            <a href="https://letsgolook.at/Droppers" target="_blank">
                <v-icon color="primary" x-small>arrow-right</v-icon>
                Claim Yours Here!
            </a>
        </div>
        <div
            v-if="
                !isDropServeBuyer &&
                isBetween(['2024-10-28 11:00'], ['2024-10-29 11:00'])
            "
            class="my-4"
        >
            [NEW VIDEO!] Watch This Quick Summary Of DropServe & See Our New
            Automated Store For Making $700/mo Commissions From Wealthy People
            <a href="https://letsgolook.at/Droppers" target="_blank">
                <v-icon color="primary" x-small>arrow-right</v-icon>
                Watch Now!
            </a>
        </div>
        <div
            v-if="
                !isDropServeBuyer &&
                isBetween(['2024-10-29 11:00'], ['2024-10-30 11:00'])
            "
            class="my-4"
        >
            [CLOSING SOON!] The New Type of Automated Store I Built To Sell
            $1,000/mo Virtual Products To Rich People (At 70% Profit Margins!)
            <a href="https://letsgolook.at/DropServiceProfits" target="_blank">
                <v-icon color="primary" x-small>arrow-right</v-icon>
                RSVP For The Final Workshops Here!
            </a>
        </div>
        <div
            v-if="
                !isDropServeBuyer &&
                isBetween(['2024-10-30 03:00'], ['2024-10-31 11:00'])
            "
            class="my-4"
        >
            [FINAL HOURS!] Enrollment Into DropServe Closing Now -- Training
            Begins Friday 1st November @ 11am New York Time!
            <a href="https://letsgolook.at/DropServiceProfits" target="_blank">
                <v-icon color="primary" x-small>arrow-right</v-icon>
                RSVP For The Final Workshops Here!
            </a>
        </div>
        <div
            v-if="
                !isDropServeBuyer &&
                isBetween(['2024-10-31 11:00'], ['2024-11-01 11:00'])
            "
            class="my-4"
        >
            [LAST CHANCE] Enrollment Into DropServe Closing Now -- Training
            Begins Friday 1st November @ 11am New York Time!
            <a href="https://letsgolook.at/Droppers" target="_blank">
                <v-icon color="primary" x-small>arrow-right</v-icon>
                Join DropServe Now!
            </a>
        </div>

        <div v-if="isReseller && !isDropServeBuyer" class="my-4">
            Your Amp Sales Funnel has been updated to a simpler, more refined
            version that will help you to improve conversions.
            <br />
            To set this up and start moving leads forward,
            <a
                href="https://docs.google.com/document/d/1wslOSG9LE4mG3TfSx0TLGokuJmsnZhY0m5heSFBhHYQ/edit"
                target="_blank"
            >
                <v-icon color="primary" x-small>arrow-right</v-icon>
                please follow the steps listed here
            </a>
        </div>

        <div v-if="showPressCableUserWelcomeMessage" class="my-4">
            You have PressCable credits remaining. Upgrade to Amp Credits
            <router-link to="/credits/convert">
                Here
                <v-icon color="primary" x-small>arrow-right</v-icon>
            </router-link>
        </div>
    </a-banner>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';

import ABanner from '@/components/ABanner';

@Component({
    components: {
        ABanner
    },
    computed: {
        ...mapGetters('user', ['isReseller']),
        ...mapState('user', [
            'isLociCycleBuyer',
            'isAsigoBuyer',
            'isDropServeBuyer',
            'isBoringMethodBuyer',
            'isNewSimplerTrafficBuyer',
            'isPressCableUser',
            'hasPressCableCredits',
            'isKamManagedUser'
        ])
    }
})
export default class WelcomeBanner extends Vue {
    isReseller!: boolean;
    isLociCycleBuyer!: boolean;
    isAsigoBuyer!: boolean;
    isDropServeBuyer!: boolean;
    isBoringMethodBuyer!: boolean;
    isNewSimplerTrafficBuyer!: boolean;
    isPressCableUser!: boolean;
    hasPressCableCredits!: boolean;
    isKamManagedUser!: boolean;

    get timestamp() {
        return Math.round(new Date().getTime() / 1000);
    }

    get showPressCableUserWelcomeMessage() {
        return this.isPressCableUser && this.hasPressCableCredits;
    }

    get isValidAsigoBuyer() {
        return this.isAsigoBuyer || this.isDropServeBuyer;
    }

    get isAsigoBuyerOnly() {
        return this.isAsigoBuyer && !this.isDropServeBuyer;
    }

    isBetween(
        [fromDate, fromZone = 'America/New_York']: string[],
        [toDate, toZone = 'America/New_York']: string[]
    ) {
        const now = this.$date();

        return (
            now.isAfter(this.$date(fromDate).tz(fromZone, true)) &&
            now.isBefore(this.$date(toDate).tz(toZone, true))
        );
    }
}
</script>
