<template>
    <v-container v-if="isAdminOrEditor">
        <v-container class="px-0 pb-6">
            <v-row>
                <v-col cols="12" md="auto">
                    <div class="text-h6 text-md-h5 font-weight-bold">
                        Author Request Statistics
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                    <stats-sheet
                        label="Request Pool"
                        :loading="loading"
                        :value="inProgressCount"
                    />
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                    <stats-sheet
                        label="Editors' Panel"
                        :loading="loading"
                        :value="inEditorialCount"
                    />
                </v-col>
            </v-row>
        </v-container>
        <v-row class="justify-space-between">
            <v-col cols="8" md="6">
                <div class="text-h6 text-md-h5 font-weight-bold">
                    My Assignments
                </div>
            </v-col>
            <v-col v-if="isEditor" cols="auto">
                <v-btn
                    class="white"
                    title="Editor Panel"
                    to="/author-requests-pool/editorial"
                    :small="$vuetify.breakpoint.smAndDown"
                >
                    <v-icon
                        :left="$vuetify.breakpoint.mdAndUp"
                        color="primary-color"
                        small
                    >
                        user-shield
                    </v-icon>
                    {{ $vuetify.breakpoint.mdAndUp ? 'Editor Panel' : null }}
                </v-btn>
            </v-col>
        </v-row>

        <v-row v-if="loading">
            <v-col v-if="$vuetify.breakpoint.smAndDown">
                <assignment-card loading class="mb-6" />
            </v-col>
            <template v-else>
                <v-col v-for="i in 3" :key="'skeleton-loader-' + i" md="4">
                    <assignment-card loading class="mb-6" />
                </v-col>
            </template>
        </v-row>

        <v-row v-else-if="hasData">
            <v-col v-if="$vuetify.breakpoint.smAndDown">
                <carousel height="220px" :show-arrows="false">
                    <v-carousel-item
                        v-for="(assignment, index) in assignments"
                        :key="'author-assignment-container--card-' + index"
                    >
                        <assignment-card
                            :assignment="assignment"
                            :action="getAction(assignment)"
                            @show-comments="showComments"
                        />
                    </v-carousel-item>
                </carousel>
            </v-col>
            <template v-else>
                <v-col
                    v-for="(assignment, index) in assignments"
                    :key="'author-assignment-container--card-' + index"
                    cols="4"
                    class="d-flex"
                >
                    <assignment-card
                        :assignment="assignment"
                        :action="getAction(assignment)"
                        @show-comments="showComments"
                    />
                </v-col>
            </template>
        </v-row>

        <a-message-bar v-else>
            You have no claimed assignments. Claim an unassigned request below
            to proceed.
        </a-message-bar>

        <v-row v-if="hasData">
            <v-col class="text-right">
                <router-link to="/author-requests-pool/assignments">
                    Show All
                </router-link>
            </v-col>
        </v-row>

        <assignment-comments
            :assignment-id="showCommentsForAssignmentId"
            @close="hideComments"
            @update="update"
        />
    </v-container>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';

import { Carousel } from '@/components/Carousel';
import { AMessageBar } from '@/components/AMessageBar';

import { AssignmentCard } from '@/components/Authoring/AssignmentCard';
import { AssignmentComments } from '@/components/Authoring/AssignmentComments';
import { StatsSheet } from '@/components/StatsSheet';

import AssignmentActionsFactory from '@/components/Authoring/AssignmentActions/AssignmentActionsFactory';

import type { Assignment } from '@/types/Assignment';

const AssignmentsSectionProps = Vue.extend({
    name: 'AssignmentsSection',
    props: {
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        },
        assignments: {
            type: Array as PropType<Assignment[]>,
            required: true
        },
        inEditorialCount: {
            type: Number,
            required: true
        },
        inProgressCount: {
            type: Number,
            required: true
        }
    }
});

@Component({
    components: {
        Carousel,
        AssignmentCard,
        AssignmentComments,
        AMessageBar,
        StatsSheet
    },
    computed: {
        ...mapGetters('user', ['isAdmin', 'isAuthor', 'isEditor'])
    }
})
export default class AssignmentsSection extends AssignmentsSectionProps {
    isAdmin!: boolean;
    isAuthor!: boolean;
    isEditor!: boolean;

    showCommentsForAssignmentId = 0;

    get hasData() {
        return this.assignments?.length;
    }

    get isAdminOrEditor() {
        return new Boolean(this.isAdmin || this.isEditor);
    }

    getAction(assignment: Assignment) {
        const AuthoringActions = new AssignmentActionsFactory(
            assignment,
            this.isEditor,
            this.isAuthor
        );

        const actions = AuthoringActions.getActions();

        const editAction = actions.find(action =>
            ['write', 'edit'].includes(action.action)
        );

        const commentsAction = actions.find(
            action => action.action === 'review'
        );

        return editAction || commentsAction;
    }

    showComments(id: number) {
        this.showCommentsForAssignmentId = id;
    }

    hideComments() {
        this.showCommentsForAssignmentId = 0;
    }

    update() {
        this.$emit('update', true);
    }
}
</script>
